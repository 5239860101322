<script setup lang="ts">
// Imports -------------------------------------------------------------------------------------------------------------
import logoIcl from "~/assets/images/logo-icl.png"
import { defineAsyncComponent, onBeforeMount, ref } from "vue"
import { useRouter } from "vue-router"

// Types ---------------------------------------------------------------------------------------------------------------
import type { Tab } from "~/types/tab.type"

// Props ---------------------------------------------------------------------------------------------------------------

// Datas ---------------------------------------------------------------------------------------------------------------

const router = useRouter()
const tabs: Tab[] = [
  {
    name: "Études",
    to: "/cartography-view/",
    icon: defineAsyncComponent(
      () => import("~icons/fa6-solid/map-location-dot"),
    ),
  },
  {
    name: "Contact",
    to: "/contact-view/",
    icon: defineAsyncComponent(() => import("~icons/fa6-solid/phone")),
  },
  {
    name: "Test",
    to: "/test/",
    icon: defineAsyncComponent(() => import("~icons/fa6-solid/house-chimney")),
  },
  {
    name: "Test 2",
    to: "/test2/",
    icon: defineAsyncComponent(() => import("~icons/fa6-solid/house-chimney")),
  },
]
const displayMenu = ref(false)
const hoverIndex = ref(-1)

// Computeds -----------------------------------------------------------------------------------------------------------

// Watchers ------------------------------------------------------------------------------------------------------------

// Functions -----------------------------------------------------------------------------------------------------------
const gotTo = (tab: Tab) => {
  router.push(tab.to)
  displayMenu.value = false
}

// Lifecycles ----------------------------------------------------------------------------------------------------------
onBeforeMount(() => {
  if (import.meta.env.VITE_APP_MODE !== "development") tabs.splice(-2, 2)
})

// Events --------------------------------------------------------------------------------------------------------------
</script>

<template>
  <header
    class="relative bg-background-primary dark:bg-background-primary-dark"
  >
    <div class="mx-auto max-w-7xl px-4 sm:px-6">
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-2 md:py-6"
      >
        <div class="flex gap-3">
          <span class="sr-only">Workflow</span>
          <img
            class="h-12 w-auto hover:cursor-pointer"
            :src="logoIcl"
            alt="logo de l'institut de cancérologie de lorraine"
            @click="gotTo(tabs[0])"
          />
        </div>
        <div class="md:hidden">
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
            @click="displayMenu = true"
          >
            <span class="sr-only">Open menu</span>
            <i-fa6-solid-bars class="size-5" />
          </button>
        </div>
        <!-- Desktop view -->
        <nav class="ml-5 hidden gap-6 md:flex">
          <div
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            class="relative flex cursor-pointer items-center gap-1 overflow-hidden uppercase text-gray-500 md:mr-2 xl:m-0"
            @mouseover="hoverIndex = tabIndex"
            @mouseleave="hoverIndex = -1"
            @click="gotTo(tab)"
          >
            <component :is="tab.icon" class="mr-1" />
            <span class="whitespace-nowrap hover:text-gray-400">
              {{ tab.name }}
            </span>
            <transition
              enter-active-class="transition-all duration-300 ease-in"
              leave-active-class="transition-all duration-300 ease-out"
              enter-from-class="-translate-x-full"
              enter-to-class="translate-x-0"
              leave-to-class="translate-x-full"
            >
              <span
                v-if="hoverIndex === tabIndex"
                class="absolute bottom-0 left-0 h-1 w-full border-b-2 border-primary"
              />
            </transition>
          </div>
        </nav>
      </div>
    </div>

    <transition
      name="menu"
      enter-active-class="duration-300 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-if="displayMenu"
        class="absolute inset-x-0 top-0 origin-top-right p-2 transition md:hidden"
      >
        <div
          class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black/5"
        >
          <div class="px-5 pb-6 pt-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" :src="logoIcl" alt="Workflow" />
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  @click="displayMenu = false"
                >
                  <span class="sr-only">Close menu</span>
                  <i-fa6-solid-xmark class="size-5" />
                </button>
              </div>
            </div>
            <div class="mt-5">
              <nav class="flex flex-col gap-y-4">
                <div
                  v-for="(tab, tabIndex) in tabs"
                  :key="tabIndex"
                  class="rounded border p-1 hover:bg-background-primary-hover"
                  @click="gotTo(tab)"
                >
                  {{ tab.name }}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>
