<script setup lang="ts">
// Imports -------------------------------------------------------------------------------------------------------------
import { computed, onMounted } from "vue"
import { useTimeoutFn } from "@vueuse/core"

// Types ---------------------------------------------------------------------------------------------------------------
import type { PopupCommonInterface } from "~/interfaces/popupCommon.interface"

// Props ---------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PopupCommonInterface>(), {
  autoHide: true,
  color: "primary",
})

// Datas ---------------------------------------------------------------------------------------------------------------

// Computeds -----------------------------------------------------------------------------------------------------------
const backgroundColor = computed(() => {
  return [
    { "bg-primary/20": props.color === "primary" },
    { "bg-secondary/30": props.color === "secondary" },
    { "bg-success/20": props.color === "success" },
    { "bg-warning/20": props.color === "warning" },
    { "bg-danger/20": props.color === "danger" },
  ]
})
const hoverBackgroundColor = computed(() => {
  return [
    { "hover:bg-primary-100": props.color === "primary" },
    { "hover:bg-secondary-100": props.color === "secondary" },
    { "hover:bg-success-100": props.color === "success" },
    { "hover:bg-warning-100": props.color === "warning" },
    { "hover:bg-danger-100": props.color === "danger" },
  ]
})
const textColorLight = computed(() => {
  return [
    { "text-primary/50": props.color === "primary" },
    { "text-secondary/50": props.color === "secondary" },
    { "text-success/50": props.color === "success" },
    { "text-warning/50": props.color === "warning" },
    { "text-danger/50": props.color === "danger" },
  ]
})
const textColor = computed(() => {
  return [
    { "text-primary": props.color === "primary" },
    { "text-secondary": props.color === "secondary" },
    { "text-success": props.color === "success" },
    { "text-warning": props.color === "warning" },
    { "text-danger": props.color === "danger" },
  ]
})
const textColorDark = computed(() => {
  return [
    { "text-primary-dark": props.color === "primary" },
    { "text-secondary-dark": props.color === "secondary" },
    { "text-success-dark": props.color === "success" },
    { "text-warning-dark": props.color === "warning" },
    { "text-danger-dark": props.color === "danger" },
  ]
})

// Watchers ------------------------------------------------------------------------------------------------------------

// Functions -----------------------------------------------------------------------------------------------------------
const { start, stop } = useTimeoutFn(() => emit("close"), 3000, {
  immediate: false,
})

// Lifecycles ----------------------------------------------------------------------------------------------------------
onMounted(() => {
  if (props.autoHide && props.color !== "danger") {
    start()
  }
})

// Events --------------------------------------------------------------------------------------------------------------
const emit = defineEmits(["close"])
</script>

<template>
  <transition
    enter-active-class="transition duration-1000 ease-out"
    enter-from-class="opacity-0 -translate-y-20"
    enter-to-class="opacity-100 translate-y-0"
    leave-from-class="opacity-100 translate-y-0"
    leave-active-class="transition duration-1000 ease-out"
    leave-to-class="opacity-0 -translate-y-full"
  >
    <!-- TODO: Revoir ce v-if inutile (eslint) -->
    <div
      v-if="true"
      class="mx-24 rounded-md px-8 py-4"
      :class="backgroundColor"
    >
      <div class="flex items-center gap-2">
        <i-fa6-solid-triangle-exclamation
          class="size-5"
          :class="[textColorLight]"
          @mouseleave="start"
          @mouseenter="stop"
        />
        <h4 :class="textColor" class="font-semibold">
          {{ title }}
        </h4>
        <button
          type="button"
          :class="[backgroundColor, textColor, hoverBackgroundColor]"
          class="ml-auto rounded-md p-1.5"
          @click="emit('close')"
        >
          <span class="sr-only">Close</span>
          <i-fa6-solid-xmark class="size-5" />
        </button>
      </div>
      <div class="mt-2" :class="textColorDark">
        <p>Message : {{ message }}</p>
      </div>
    </div>
  </transition>
</template>
