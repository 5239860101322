<script setup lang="ts">
// Imports -------------------------------------------------------------------------------------------------------------
import { useMagicKeys } from "@vueuse/core"
import { watch } from "vue"

// Types ---------------------------------------------------------------------------------------------------------------
import type { FullScreenCommonInterface } from "~/interfaces/fullScreenCommon.inteface"

// Props ---------------------------------------------------------------------------------------------------------------
const props = defineProps<FullScreenCommonInterface>()

// Datas ---------------------------------------------------------------------------------------------------------------
const { escape } = useMagicKeys()

// Computeds -----------------------------------------------------------------------------------------------------------

// Watchers ------------------------------------------------------------------------------------------------------------
watch(escape, (newValue) => {
  if (newValue) {
    emit("close")
  }
})

// Functions -----------------------------------------------------------------------------------------------------------

// Lifecycles ----------------------------------------------------------------------------------------------------------

// Events --------------------------------------------------------------------------------------------------------------
const emit = defineEmits(["close"])
</script>

<template>
  <div
    class="flex size-full items-center justify-center bg-black"
    @click.prevent="emit('close')"
  >
    <img :src="props.src" :alt="props.alt" class="w-4/5" />
  </div>
</template>
