<script setup lang="ts">
// Imports -------------------------------------------------------------------------------------------------------------
import { ref } from "vue"
import duck from "~/assets/images/duck.webp"

// Types ---------------------------------------------------------------------------------------------------------------
import type { ImageCommonInterface } from "~/interfaces/imageCommon.interface"
import { fullScreenCommonKey, safeInject } from "~/injectionKeys"

// Props ---------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<ImageCommonInterface>(), {
  loadingMessage: "Chargement de l'image...",
  allowFullScreen: false,
})

// Datas ---------------------------------------------------------------------------------------------------------------
const src = props.src ?? duck
const alt = duck ? "Un canard en plastique jaune" : props.alt
const isLoading = ref(true)

// Computeds -----------------------------------------------------------------------------------------------------------

// Watchers ------------------------------------------------------------------------------------------------------------

// Functions -----------------------------------------------------------------------------------------------------------
const onLoad = () => {
  isLoading.value = false
}
const toggleFullScreen = () => {
  if (!props.allowFullScreen) return
  fullScreen.value = {
    src: src,
    alt: alt,
  }
}

// Lifecycles ----------------------------------------------------------------------------------------------------------
let fullScreen = safeInject(fullScreenCommonKey)

// Events --------------------------------------------------------------------------------------------------------------
</script>

<template>
  <!-- Todo : Revoir le duck et img par défaut plus proprement -->
  <div v-if="src !== duck" class="relative">
    <img
      :src="src"
      :alt="alt"
      class="transition-opacity duration-300"
      :class="{ 'opacity-0': isLoading, 'cursor-pointer': allowFullScreen }"
      @load="onLoad"
      @click="toggleFullScreen()"
    />
    <div
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <div class="flex flex-col items-center gap-2 text-secondary">
        <span
          class="block size-6 animate-spin rounded-full border-4 border-t-primary"
        />
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</template>
