<script setup lang="ts">
// Imports -------------------------------------------------------------------------------------------------------------
import { provide, computed, ref, ComputedRef } from "vue"
import { useWindowSize } from "@vueuse/core"
import {
  popupCommonKey,
  windowSizeKey,
  fullScreenCommonKey,
} from "~/injectionKeys"

// Types ---------------------------------------------------------------------------------------------------------------
import type { PopupCommonInterface } from "~/interfaces/popupCommon.interface"
import type { FullScreenCommonInterface } from "~/interfaces/fullScreenCommon.inteface"
import type { WindowSize } from "~/types/windowSize.type"

// Props ---------------------------------------------------------------------------------------------------------------

// Datas ---------------------------------------------------------------------------------------------------------------
const popup = ref<PopupCommonInterface | null>(null)
const fullScreen = ref<FullScreenCommonInterface | null>(null)
const windowSize = useWindowSize()

// Computeds -----------------------------------------------------------------------------------------------------------
const windowSizes: ComputedRef<WindowSize> = computed(() => ({
  sm: windowSize.width.value < 768,
  md: windowSize.width.value >= 768 && windowSize.width.value < 1024,
  lg: windowSize.width.value >= 1024 && windowSize.width.value < 1280,
  xl: windowSize.width.value >= 1280 && windowSize.width.value < 1536,
  "2xl": windowSize.width.value >= 1536,
}))

// Watchers ------------------------------------------------------------------------------------------------------------

// Functions -----------------------------------------------------------------------------------------------------------

// Lifecycles ----------------------------------------------------------------------------------------------------------
provide(popupCommonKey, popup)
provide(fullScreenCommonKey, fullScreen)
provide(windowSizeKey, windowSizes)

// Events --------------------------------------------------------------------------------------------------------------
</script>

<template>
  <div>
    <FullScreenCommon
      v-if="fullScreen"
      v-bind="fullScreen"
      @close="fullScreen = null"
    />
    <div
      v-show="!fullScreen"
      class="flex min-h-screen flex-col bg-background-primary text-base text-primary dark:bg-background-primary-dark dark:text-primary-dark"
    >
      <TopBar />
      <BreadcrumbsCommon v-if="$route.path !== '/cartography-view/'" />
      <PopupCommon v-if="popup" v-bind="popup" @close="popup = null" />
      <router-view v-slot="{ Component, route }">
        <transition
          appear
          enter-from-class="opacity-0"
          leave-to-class="opacity-0"
          enter-active-class="transition duration-300"
          leave-active-class="transition duration-300"
        >
          <component :is="Component" :key="route.path" class="min-h-screen" />
        </transition>
      </router-view>
      <BottomBar />
    </div>
  </div>
</template>
