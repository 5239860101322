import { InjectionKey, inject } from "vue"

// Type ----------------------------------------------------------------------------------------------------------------
import type { Ref } from "vue"
import type { PopupCommonInterface } from "~/interfaces/popupCommon.interface"
import type { FullScreenCommonInterface } from "~/interfaces/fullScreenCommon.inteface"
import type { WindowSize } from "~/types/windowSize.type"

// Keys ----------------------------------------------------------------------------------------------------------------
export const popupCommonKey: InjectionKey<Ref<PopupCommonInterface | null>> =
  Symbol("popup")
export const fullScreenCommonKey: InjectionKey<
  Ref<FullScreenCommonInterface | null>
> = Symbol("fullScreen")
export const windowSizeKey: InjectionKey<Ref<WindowSize>> = Symbol("windowSize")

// Functions -----------------------------------------------------------------------------------------------------------
export function safeInject<T>(key: InjectionKey<T>): T {
  const resolved = inject(key)
  if (!resolved) {
    throw new Error(`Failed to resolve ${key.description}`)
  }
  return resolved
}
